<template>
 <div style="height: 100px; margin-top: -420px">
  <h1 style="color:  #66ff00; font-weight: bold; font-size: 50px;"  >
    Tambah Asset
  </h1>
  <form class="form" @submit.prevent="saveOrganization"> 
<body class="oi">
  <div class="con">
    <div class="ev">
      <input type="text" required class="unit"  name="name" id="name" v-model="contractAddress">
      <div class="in"  for="name">Masukan Nama</div>
    </div>
  </div>
</body>
<div class="con" style="margin-top: -300px;">
    <div class="ev">
      <input type="text" required class="unit"name="name" id="contact" v-model="creatorAddress">
      <div class="in"  for="name">Alamat Creator</div>
    </div>
  </div>
   
  <div class="con" style="margin-top: 150px;">
    <div class="ev">
      <input type="text" required class="unit"  name="name" id="contact" v-model="contractType">
      <div class="in"  for="name">Contact</div>
    </div>
  </div>

  <div class="con" style="margin-top: -200px;">
    <div class="ev">
      <input type="text" required class="unit" name="name"id="address" v-model="address">
      <div class="in" for="address">Addresss</div>
    </div>
  </div>
 <div style="position: absolute;left: 650px; top: 500px;">
  <button @click="cancelAdd" style="display: inline-block; width: 100px; height: 50px; border: 5px solid #66ff00; border-radius: 10px; color: aliceblue;"  >Cancel</button> |
  <button type="submit" style="display: inline-block; width: 100px; height: 50px; border: 5px solid #66ff00; border-radius: 10px; color: aliceblue">Save</button> <br>
  <a href="/dasbord">Kembali</a>
</div>
</form>

</div>





    <!-- <div class="init">
    
    <form class="form" @submit.prevent="saveOrganization"> 
    <p class="email"> 
        <input type="text " name="name" id="name" v-model="contractAddress" class="none"/> 
        <label for="name">ContractAddress </label> 
    </p> 
  
    <p class="text"> 
        <input type="text " name="name" id="contact" v-model="creatorAddress" class="none"/> 
        <label for="name">CreatorAddress</label>  
    </p> 

    <p class="text"> 
        <input type="text " name="name" id="contact" v-model="contractType" class="none"/> 
        <label for="name">Contact</label>  
    </p> 
    <p class="text"> 
        <input type="text " name="name"id="address" v-model="address" class="none"/> 
        <label for="address">Address</label>  
    </p> 
  
    <div class="submit"> 
        <button @click="cancelAdd">Cancel</button> |
        <button type="submit">Save</button> 
    </div> 
  
</form>
    </div> -->
</template>

<script>
export default {
    data() {
        return {
            contractAddress:'',
            creatorAddress:'',
            contractType:'',
            address:'',
        };
    },
    methods: {
        cancelAdd() {
            this.$router.push({ name: 'AssetOrganization'});
        },
        async saveOrganization() {
            const data = {
                contractAddress: this.contractAddress,
                creatorAddress: this.creatorAddress,
                contractType: this.contractType,
               address: this.address,
            }
            console.log('Hasil', data)
            try{
                await this.$axios
                .post('/organizations-assets', data)
                .then((res) => {
                    alert('data di tambah',res)
                    window.localStorage.href='/assetOrganizations'
                    return
                })
            }catch(error){}
        },
    },
};
</script>