import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ListOrganization from '@/views/List/ListOrganization.vue'
import AddOrganization from '@/views/List/AddOrganization.vue'
import store from '../store';
import Dasbord from '../views/dasbord/Dasbord.vue';
import EditOrganization from '../views/List/EditOrganization.vue';
import AssetOrganization from '../views/aset/ListAssetOrganization.vue';
import AddAsset from '../views/aset/AddAssetOrganization.vue';
const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true,
    },
  },
  
  
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: {
      requireAuth: true,
    },
  },
{
  path:'/dasbord',
  name:'dasbord',
  component: Dasbord,
  meta: {
    requireAuth: true,
  }
},
{
  path: '/listOrganizations/edit/:id',
  name: 'EditOrganization',
  component: EditOrganization,
  meta: {
    requireAuth: true,
  },
},
  {
    path: '/listOrganizations',
    name: 'ListOrganization',
    component: ListOrganization,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/listOrganizations/add',
    name: 'AddOrganization',
    component: AddOrganization,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/assetOrganizations',
    name: 'AssetOrganization',
    component: AssetOrganization,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/assetOrganizations/add',
    name: 'AddAsset',
    component: AddAsset,
    meta: {
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {


  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ name: 'login' });
  }if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({ path: '/' });
    }}}
    next()
});

export default router;