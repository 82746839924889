<template>
  <body>
    <main class="Tabel">

  <section class="Tabel_Header">  
    <h1>Data User</h1>
    <div>
      <input placeholder="User"/>
      <button >+ Add User</button>
      
    </div>
  </section>


  <section class="Tabel_Body">
    <table>
      <thead>
        <tr >
          <th>name</th>
          <th>Phone</th>
          <th>Address</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody v-for="org in  organizations" :key="org.id">
        <td >{{ org.name }}</td>
        <td > {{ org.contact }}</td>
        <td >{{ org.address }}</td>
        <td>
          <button  class="status edit">Edit</button> |
          <button class="status delet">Deleted</button>
        </td>
      </tbody>
    </table>
    
  </section>
  <a href="/dasbord">Kembali</a>
</main>
</body>
</template>
<script>
export default {
  data() {
    return {
      
      loading: true,
      organizations: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Name" },
        { key: "imageUrl", label: "Image" },
        { key: "contact", label: "Contact" },
        { key: "address", label: "Address" },
        { key: "actions", label: "Actions" },        
      ],      
    };
  },
  mounted() {
    this.fetchOrganizations();
  },
  methods: {
    fetchOrganizations() {
      
      this.$axios.get('/organizations')
        .then(response => {
          this.organizations = response.data.map(org => ({
            id: org._id,
            name: org.name,
            imageUrl: org.imageUrl,
            contact: org.contact,
            address: org.address,
          }));
          console.log("organizations:", this.organizations);
          
        ;
        })
        .catch(error => {
          alert('Error fetching organizations: ' + error.message);
        });
    },
  }
};
</script>
<style>


</style>
