<template>
     <div class="init">
    
    <form class="form" @submit.prevent="editOrganization"> 
    <p class="email"> 
        <input type="text " name="name" id="name" v-model="name" class="none"/> 
        <label for="name">Nama </label> 
    </p> 
  
    <p class="web" >
        <input v-model="image" id="image"/>
       <label for="Image URL:" label-for="image">Image</label>
    </p> 

    <p class="text"> 
        <input type="text " name="name" id="contact" v-model="contact" class="none"/> 
        <label for="name">Contact</label>  
    </p> 
    <p class="text"> 
        <input type="text " id="address" v-model="address" class="none"/> 
        <label for="address">Address</label>  
    </p> 
  
    <div class="submit"> 
        <button @click=" cancelEdit">Cancel</button> |
        <button type="submit">Save</button> 
    </div> 
  
</form>
</div>
    </template>
    <script>
    export default {
      data() {
        return {
          id: this.$route.params.id,
          name: '',
          image: '',
          contact: '',
          address: '',
        };
      },
      mounted() {
        this.fetchOrganization();
      },
      methods: {
        fetchOrganization() {
          this.$axios.get(`/organizations/${this.id}`)
            .then((res) => {
              this.name = res.data.name;
              this.image = res.data.imageUrl;
              this.contact = res.data.contact;
              this.address = res.data.address;
            })
            .catch((error) => {
              alert("Error fetching organization: " + error.message);
            });
        },
        cancelEdit() {
          this.$router.push({ name: 'dasbord' });
        },     
        async editOrganization() {
          const data = {
           name : this.name,
           contact: this.contact,
           address: this.address,
           imageUrl: this.image
          }
          console.log('pload', data)
            try{
             await this.$axios
            .put('/organizations/'+this.id, data)
            .then((res) => {
              alert('data di ubah',)
              window.location.href='/dasbord'
              return
            }) 
            } catch(error){}
          
        },
      }
    }
    </script>
    <!-- <style>
    .init{
        display: flex;
      justify-content: center; /* Menengahkan secara horizontal */
      align-items: center; /* Menengahkan secara vertikal */
      height: 100vh; /* Mengisi seluruh tinggi viewport */
    }
    input, textarea { 
        padding: 9px; 
        border: solid 1px #E5E5E5; 
        outline: 0; 
        font: normal 13px/100% Verdana, Tahoma, sans-serif; 
        width: 200px; 
        background: #FFFFFF; 
        } 
      
    textarea { 
        width: 400px; 
        max-width: 400px; 
        height: 150px; 
        line-height: 150%; 
        } 
          
    input, textarea { 
        box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px; 
        -moz-box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px; 
        -webkit-box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px; 
        
        }
        input, textarea { 
        background: -webkit-gradient(linear, left top, left 25, from(#504d4d), to(#FFFFFF)); 
        background: -moz-linear-gradient(top, #EEEEEEEE, #FFFFFF 25px); 
        }
        
      
    input:hover, textarea:hover, 
    input:focus, textarea:focus { 
        border-color: #C9C9C9; 
        
        } 
      
    .form label { 
        margin-left: 10px; 
        color: #999999; 
        } 
      
    .submit input { 
        width: auto; 
        padding: 9px 15px; 
        background: #617798; 
        border: 0; 
        font-size: 14px; 
        color: #FFFFFF; 
       
        }
        
    </style> -->