<template>
  <div style="height: 100px; margin-top: -420px">
  <h1 style="color:  #66ff00; font-weight: bold; font-size: 50px;  border-bottom: 2px solid  #66ff00;"  >
    Tambah List
  </h1>
  <form class="form" @submit.prevent="saveOrganization"> 
<body class="oi">
  <div class="con">
    <div class="ev">
      <input type="text" required class="unit" v-model="name">
      <div class="in"  for="name">Masukan Nama</div>
    </div>
  </div>
</body>
<div class="con" style="margin-top: -300px;">
    <div class="ev">
      <input type="text" required class="unit"  v-model="image"  name="name" id="name">
      <div class="in"  for="Image URL:" label-for="image">Image</div>
    </div>
  </div>
   
  <div class="con" style="margin-top: 150px;">
    <div class="ev">
      <input type="text" required class="unit"  name="name" id="contact" v-model="contact">
      <div class="in" for="name">contact</div>
    </div>
  </div>

  <div class="con" style="margin-top: -200px;">
    <div class="ev">
      <input type="text" required class="unit" id="address" v-model="address">
      <div class="in" for="address">Addresss</div>
    </div>
  </div>
 <div style="position: absolute;left: 650px; top: 500px;">
  <button @click="cancelAdd" style="display: inline-block; width: 100px; height: 50px; border-radius: 20px; font-weight: bold; color: #66ff00;" class="transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-100 ..." >Cancel</button> |
  <button type="submit" style="display: inline-block; width: 100px; height: 50px; border-radius: 20px; font-weight: bold; color: #66ff00;" class="transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-100 ..." >Save</button> <br>
  <a href="/dasbord">Kembali</a>
</div>
</form>

</div>
  
    <!-- <div class="init">
      
    
        
        <p class="email"> 
            <input type="text " name="name" id="name" v-model="name" class="none"/> 
            <label for="name">Nama </label> 
        </p> 
      
        <p class="web" >
            <input v-model="image" id="image"/>
           <label for="Image URL:" label-for="image">Image</label>
        </p> 

        <p class="text"> 
            <input type="text " name="name" id="contact" v-model="contact" class="none"/> 
            <label for="name">Contact</label>  
        </p> 
        <p class="text"> 
            <input type="text " id="address" v-model="address" class="none"/> 
            <label for="address">Address</label>  
        </p>  -->
      
        <!-- <div class="submit"> 
            <button @click="cancelAdd">Cancel</button> |
            <button type="submit">Save</button> 
        </div>  -->
      
    
    <!-- </div> -->
    </template>
    <script>

    export default {
      data() {
        return {
          name: '',
          image: '',
          contact: '',
          address: '',
         
        };
      },
      methods: {
        cancelAdd() {
          this.$router.push({ name: 'dasbord' });
        },
        async saveOrganization() {
          const data = {
           name : this.name,
           contact: this.contact,
           address: this.address,
           imageUrl: this.image
          }
          console.log('pload', data)
            try{
             await this.$axios
            .post('/organizations', data)
            .then((res) => {
              alert('data di tambah',)
              window.location.href='/dasbord'
              return
            }) 
            } catch(error){}
          
        },
      },
    };
    </script>
    <style>
    body.oi{
     display: flex;
     min-height: 100vh;
     justify-content: center;
     align-items: center;
     background: #000000;
    }
    .con{
    width: 680px;
    }
    .ev{
      position: relative;
      height: 80px;
    }
    .unit{
      position: absolute;
      width: 60%;
      outline: none;
      font-size: 2.2rem;
      padding: 0 30px;
      line-height: 50px;
      border-radius: 10px;
      border: 2px solid #f0ffff;
      background: transparent;
      transition: 0,1s ease;
      z-index: 1111;
      left: -5px;
      margin-top: -300px;
  margin-left: 140px;
    }
    .in{
      position: absolute;
      font-size: 1.6rem;
      color: #f0ffff;
      padding: 0 25px;
      margin: 0 20px;
      background-color: #000000;
      transition: 0,2s ease;
      margin-top: -300px;
  margin-left: 150px;
    }
    .unit:focus,
    .unit:valid{
      color: #66ff00;
      border: 4px solid #66ff00;
    }
    .unit:focus + .in,
    .unit:valid + .in{
      color: #66ff00;
      height: 30px;
      line-height: 30px;
      padding: 0 12px;
      transform: translate(-15px,-16px) scale(0.88);
      z-index: 1111;
     
    }
    </style>