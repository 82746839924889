<template>
  <a href="/listOrganizations/add">add</a>
  <table>
      <thead>
        <tr>
          <th>id</th>
          <th>name</th>
          <th>Image</th>
          <th>contact</th>
          <th>address</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="org in organizations" :key="org.id">
          <td>{{ org.id }}</td>
          <td>{{ org.name }}</td>
          <td>
            <img :src="org.imageUrl" style="width: 50px"  />
          </td>
          <td>{{ org.contact }}</td>
          <td>{{ org.address }}</td>
          <td>
            <button class="button" @click="routeToEditOrganization(org.id)">edited</button>|
            <button class="button" @click="deleteOrganization(org.id)">deleted</button>
          </td>
        </tr>
      </tbody>
   </table>
   <a href="/dasbord">Back</a>
</template>

<script>
export default {
  data() {
    return {
      organizations: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Name" },
        { key: "imageUrl", label: "Image" },
        { key: "contact", label: "Contact" },
        { key: "address", label: "Address" },
        { key: "actions", label: "Actions" },        
      ],      
    };
    
  },
  mounted() {
    this.fetchOrganizations();
  },
  methods: {
    fetchOrganizations() {
      
      this.$axios.get('/organizations')
        .then(response => {
          this.organizations = response.data.map(org => ({
            id: org._id,
            name: org.name,
            imageUrl: org.imageUrl,
            contact: org.contact,
            address: org.address,
          }));
          console.log("organizations:", this.organizations);
          
        })
        .catch(error => {
          alert('Error fetching organizations: ' + error.message);
        });
    },
    routeToAddOrganization() {
      this.$router.push({ name: 'AddOrganization' });
    },
    routeToEditOrganization(id) {
      this.$router.push({ name: 'EditOrganization', params: { id } });
    },
    deleteOrganization(id) {
      this.$axios.delete(`/organizations/${id}`)
        .then(() => {
          this.fetchOrganizations();
        })
        .catch(error => {
          alert('Error deleting organization: ' + error.message);
        });
    },
  },
};
</script>
<!-- <style>


table{
  width: 70%;
  margin: 0 auto;
  font-size: 14px;
  color: #444;
  white-space: nowrap; 
  border-collapse: collapse;
  box-shadow: 8px 13px 82px 30px rgba(0,0,0,0.75);
  -webkit-box-shadow: 8px 13px 82px 30px rgba(0,0,0,0.75);
  -moz-box-shadow: 8px 13px 82px 30px rgba(0, 0, 0, 0.068);
   >thead{
    background-color: rgb(7, 44, 228);
    color: whitesmoke;
  }
  >thead th{
    padding: 15px;
  }
   th, td{
    border: 1px solid rgb(1, 1, 1);
    padding: 4px 7px;
  }
  >tbody>tr{
    background-color: rgb(237, 237, 242);
    transition: 0.3 ease-in-out;
  }
  >tbody>tr:nth-child(even){
    background-color: antiquewhite; 
 }
 >tbody>tr:hover{
    filter: drop-shadow(0 2px 6px #0002)
  }
}
.button{
      display: inline-block;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: #ffffff;
      background-color: #4CAF50;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease;
    }

    .button:hover {
      background-color: #df766f;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    }

    .button:active {
      transform: translateY(2px);
      box-shadow: none;
    }
</style> -->