<template>
    <body>
        <main class="Tabel">
    <div >
        <section class="Tabel_Header">         
          <h1>List Asset</h1>   
          </section>
          
    <select @change="handlechange"  id="voice-search" style="width: 200px ; " class="Tabel_Header bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"  placeholder="Search..." required>
        <option  v-for="org in assets" :value="org.contractAddress" :key="org.id">
            {{ org.contractAddress }}
        </option>
       
    </select>
   </div>
   <a href="/assetOrganizations/add">New</a>
   <section class="Tabel_Body">
<table>
      <thead>
        <tr>
          <th>id</th>
          <th>Nama</th>
          <th>Alamat </th>
          <th>contact</th>
          <th></th>
         
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr  v-for="org in filterArray" :key="org.id">
          <td>{{ org.id }}</td>
          <td>{{ org.contractAddress }}</td>
          <td>{{ org.creatorAddress }}</td>
          <td>{{ org.contractType }}</td>
          
          <td>{{ org. actions }}</td>
          <td>
            <button class="status edit" @click="routeToEditOrganization(org.id)">edited</button>|
            <button class="status delet" @click="deleteAssets(org.id)">deleted</button>
          </td>
        </tr>
      </tbody>
   </table>
   </section>
   <a href="/dasbord">Kembali</a>
   </main>
</body>
</template>

<script>
export default {
    name: "AssetOrganization",
    data() {
        return {
            assets: [],
            filterArray:[],
            optionvalue:"",
            fields: [
                {key:"_id", label: "ID"},
                {key:"contractAddress", label: "Contract Address"},
                {key:"creatorAddress", label: "Creator Addreess"},
                {key:"contractType", label: "Contract Type"},
                {key: "address", label:"adsress"},
                {key:"actions", label:"Actions"},
            ],
        };
    },
    mounted() {
        this.fetchAssets();
    },
    methods: {
        handlechange(event){
         this.optionvalue=event.target.value
         console.log(this.optionvalue)
         this.filterdata()
        },
        filterdata(){
        this.filterArray=[...this.assets].filter(item=>item.contractAddress.toString()===this.optionvalue.toString())
        console.log(this.filterArray)
        },

        fetchAssets(){
            this.$axios.get('/organizations-assets')
            .then(response => {
                this.assets = response.data.map(org => ({
                    id: org._id,
                    contractAddress: org.contractAddress,
                    creatorAddress: org.creatorAddress,
                    contractType: org.contractType,
                    address: org.address,
                    actions: org.actions,
                }));
                console.log("Assets", this.assets);
            })
            .catch(error => {
                alert('Error Asset' +error.message);
            });
        },
        deleteAssets(id) {
            console.log('data hilang', this.assets)
      this.$axios.delete(`/organizations-assets/${id}`)
        .then(() => {
            this.fetchAssets();
        })
        .catch(error => {
          alert('Error deleting organization: ' + error.message);
        });
    },
    }
}
</script>



<!-- <style>

.sidebar {
    width: 250px;
    background-color: #333;
    color: #fff;
    padding-top: 10px;
}

.sidebar-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar-item {
    padding: 10px;
    border-bottom: 1px solid #555;
}

.sidebar-link {
    display: block;
    color: #fff;
    text-decoration: none;
}

.sidebar-link:hover {
    background-color: #555;
}

/* Styling dropdown */
.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    left: 100%; /* Membuat dropdown muncul di sebelah kanan */
    top: 0;
    background-color: #444;
    padding: 10px;
    list-style-type: none;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu li {
    margin-bottom: 5px;
}

.dropdown-menu a {
    color: #fff;
    text-decoration: none;
}

.dropdown-menu a:hover {
    text-decoration: underline;
}

</style> -->